import { https } from '../utils/request.js';

export const getUserData = data => https("get", `/api/twitter/twitter_users`, data)

export const tuiTePrice = data => https("get", `/api/twitter/twitter_prices`, data)
export const addVip = data => https("post", `/api/twitter/twitter_add_vip`, data)
export const deleteVip = data => https("post", `/api/twitter/twitter_delete_vip`, data)
export const twitterOrders = data => https("get", `/api/twitter/twitter_order`, data)
export const twitterSearch = data => https("get", `/api/twitter/twitter_search`, data)
export const twitterReduce = data => https("post", `/api/twitter/twitter_reduce_days`, data)
export const twitterFunctions = data => https("get", `/api/twitter/twitter_functions`, data)

export const AddtwitterFunctions = data => https("post", `/api/twitter/twitter_functions`, data)

export const twitterUserFunctions = data => https("get", `/api/twitter/twitter_user_functions`, data)
export const AddtwitterUserFunctions = data => https("post", `/api/twitter/twitter_user_functions`, data)
export const DeltwitterUserFunctions = data => https("delete", `/api/twitter/twitter_user_functions`, data)



//登录
export const getLoginUserData = data => https("post", `/api/user/user`, data)

//单身恋爱盲盒
export const getDanShenUserData = data => https("get", `/api/manghe/select_outcash`, data)
export const getDanShenConfirm_tanfer= data => https("post", `/api/manghe/confirm_tranfer`, data)
export const getDanShenTranfer= data => https("post", `/api/manghe/ds_tranfer`, data)

//cpdd盲盒
export const getCPDDUserData = data => https("get", `/api/manghe/cpdd_select_outcash`, data)
export const getCPDDConfirm_tanfer= data => https("post", `/api/manghe/cpdd_confirm_tranfer`, data)
export const getCPDDTranfer= data => https("post", `/api/manghe/cpdd_tranfer`, data)

//浅拍
export const QaiPaiTranFer= data => https("post", `/api/qianpai/qianpai_tranfer`, data)

export const getQianPaiUserData = data => https("get", `/api/qianpai/select_outcash`, data)