<template>
    <div class="header">
        <!-- 折叠按钮 -->
        <div class="collapse-btn" @click="collapseChage">
            <i v-if="!collapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
        </div>
        <div class="logo">提现管理系统</div>
        <div class="header-right">
            <div class="header-user-con">
                <!-- 消息中心 -->
              <!--  <div class="btn-bell">
                    <el-tooltip
                        effect="dark"
                        :content="message?`有${message}条未读消息`:`消息中心`"
                        placement="bottom"
                    >
                        <router-link to="/tabs">
                            <i class="el-icon-bell"></i>
                        </router-link>
                    </el-tooltip>
                    <span class="btn-bell-badge" v-if="message"></span>
                </div> -->
                <!-- 用户头像 -->
                <div class="user-avator">
                    <img src="../assets/img/image.png" />
                </div>
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{username}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
							<el-dropdown-item divided command="modify">修改密码</el-dropdown-item>
                            <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
    </div>
	<el-dialog title="修改密码" v-model="zf_editVisible" width="30%">
	    <el-form ref="form" :model="form" label-width="70px">
	        <el-form-item label="旧密码">
	            <el-input v-model="form.old_password"></el-input>
	        </el-form-item>
	        <el-form-item label="新密码">
	            <el-input v-model="form.new_password"></el-input>
	        </el-form-item>
	    </el-form>
	    <template #footer>
	        <span class="dialog-footer">
	            <el-button @click="zf_editVisible = false">取 消</el-button>
	            <el-button type="primary" @click="modify_password()">确 定</el-button>
	        </span>
	    </template>
	</el-dialog>
</template>
<script>
	import {ModifyPassword} from "../api/index.js";
export default {
    data() {
        return {
            fullscreen: false,
            name: "linxin",
            message: 2,
			zf_editVisible:false,
			form:{
				old_password:"",
				new_password:""
			}
        };
    },
    computed: {
        username() {
            let username = localStorage.getItem("ms_username");
            return username ? username : this.name;
        },
        collapse() {
            return this.$store.state.collapse;
        }
    },
    methods: {
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            if (command == "loginout") {
                localStorage.removeItem("ms_username");
                this.$router.push("/login");
            }else if(command=="modify"){
				this.zf_editVisible=true
			}
        },
		//修改密码
		modify_password(){
			// ModifyPassword(this.form).then( res=> {
			// 	console.log(res)
			// })
			
			ModifyPassword(this.form).then(res => {
			   console.log(res)
			   if(res.code == -1 ){
				   this.$message.error(res.msg);
			   }else{
				   this.$message.error(res.data);
				   this.zf_editVisible=false
				   localStorage.removeItem("ms_username");
				   this.$router.push("/login");
			   }
			});
		},
        // 侧边栏折叠
        collapseChage() {
            this.$store.commit("hadndleCollapse", !this.collapse);
        }
    },
    mounted() {
        if (document.body.clientWidth < 1500) {
            this.collapseChage();
        }
    }
};
</script>
<style scoped>
.header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    font-size: 22px;
    color: #fff;
}
.collapse-btn {
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 70px;
}
.header .logo {
    float: left;
    width: 250px;
    line-height: 70px;
}
.header-right {
    float: right;
    padding-right: 50px;
}
.header-user-con {
    display: flex;
    height: 70px;
    align-items: center;
}
.btn-fullscreen {
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
.btn-bell-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #fff;
}
.btn-bell .el-icon-bell {
    color: #fff;
}
.user-name {
    margin-left: 10px;
}
.user-avator {
    margin-left: 20px;
}
.user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.el-dropdown-link {
    color: #fff;
    cursor: pointer;
}
.el-dropdown-menu__item {
    text-align: center;
}
</style>
