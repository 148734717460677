import {
	createRouter,
	createWebHistory
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [{
	path: '/',
	redirect: '/dan_outcash'
}, {
	path: "/",
	name: "Home",
	component: Home,
	children: [{
			path: '/dan_outcash',
			name: 'dan_outcash',
			meta: {
				title: '单身盲盒'
			},
			component: () => import( /* webpackChunkName: "subindex" */
				'../views/danshenmanghe/dan_outcash.vue')
		},
		{
			path: '/cpdd_outcash',
			name: 'cpdd_outcash',
			meta: {
				title: 'cpdd盲盒'
			},
			component: () => import( /* webpackChunkName: "subindex" */
				'../views/cpddmanghe/cpdd_outcash.vue')
		},
		{
			path: '/qianpai_outcash',
			name: 'qianpai_outcash',
			meta: {
				title: '浅拍'
			},
			component: () => import( /* webpackChunkName: "subindex" */
				'../views/qianpai/outcash.vue')
		},
		{
			path: '/twitter_function',
			name: 'twitter_function',
			meta: {
				title: '功能列表'
			},
			component: () => import( /* webpackChunkName: "subindex" */
				'../views/twitter_function.vue')
		},
		{
			path: '/twitter_user_functions',
			name: 'twitter_user_functions',
			meta: {
				title: '用户功能列表'
			},
			component: () => import( /* webpackChunkName: "subindex" */
				'../views/twitter_user_functions.vue')
		},
		{
			path: '/tuiteindex',
			name: 'tuiteindex',
			meta: {
				title: '用户列表'
			},
			component: () => import( /* webpackChunkName: "subindex" */
				'../views/TuiTeHome.vue')
		},
		{
			path: '/tuiterecord',
			name: 'tuiterecord',
			meta: {
				title: '充值记录'
			},
			component: () => import( /* webpackChunkName: "subindex" */
				'../views/twitter_record.vue')
		}, {
			path: '/subindex',
			name: 'subindex',
			meta: {
				title: '下级列表'
			},
			component: () => import( /* webpackChunkName: "subindex" */
				'../views/Subindex.vue')
		}, {
			path: '/singlesub',
			name: 'singlesub',
			meta: {
				title: '下级收益详情'
			},
			component: () => import( /* webpackChunkName: "singlesub" */
				'../views/SingleSub.vue')
		},
		{
			path: '/withdraw',
			name: 'withdraw',
			meta: {
				title: '提现详情'
			},
			component: () => import( /* webpackChunkName: "withdraw" */
				'../views/WithDraw.vue')
		},
		{
			path: '/distribution',
			name: 'distribution',
			meta: {
				title: '分销配置'
			},
			component: () => import( /* webpackChunkName: "distribution" */
				'../views/Distribution.vue')
		},
		{
			path: "/table",
			name: "basetable",
			meta: {
				title: '表格'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/BaseTable.vue")
		}, {
			path: "/charts",
			name: "basecharts",
			meta: {
				title: '图表'
			},
			component: () => import(
				/* webpackChunkName: "charts" */
				"../views/BaseCharts.vue")
		}, {
			path: "/form",
			name: "baseform",
			meta: {
				title: '表单'
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/BaseForm.vue")
		}, {
			path: "/tabs",
			name: "tabs",
			meta: {
				title: 'tab标签'
			},
			component: () => import(
				/* webpackChunkName: "tabs" */
				"../views/Tabs.vue")
		}, {
			path: "/donate",
			name: "donate",
			meta: {
				title: '鼓励作者'
			},
			component: () => import(
				/* webpackChunkName: "donate" */
				"../views/Donate.vue")
		}, {
			path: "/permission",
			name: "permission",
			meta: {
				title: '权限管理',
				permission: true
			},
			component: () => import(
				/* webpackChunkName: "permission" */
				"../views/Permission.vue")
		}, {
			path: "/i18n",
			name: "i18n",
			meta: {
				title: '国际化语言'
			},
			component: () => import(
				/* webpackChunkName: "i18n" */
				"../views/I18n.vue")
		}, {
			path: "/upload",
			name: "upload",
			meta: {
				title: '上传插件'
			},
			component: () => import(
				/* webpackChunkName: "upload" */
				"../views/Upload.vue")
		}, {
			path: "/icon",
			name: "icon",
			meta: {
				title: '自定义图标'
			},
			component: () => import(
				/* webpackChunkName: "icon" */
				"../views/Icon.vue")
		}, {
			path: '/404',
			name: '404',
			meta: {
				title: '找不到页面'
			},
			component: () => import( /* webpackChunkName: "404" */
				'../views/404.vue')
		}, {
			path: '/403',
			name: '403',
			meta: {
				title: '没有权限'
			},
			component: () => import( /* webpackChunkName: "403" */
				'../views/403.vue')
		}
	]
}, {
	path: "/login",
	name: "Login",
	meta: {
		title: '登录'
	},
	component: () => import(
		/* webpackChunkName: "login" */
		"../views/Login.vue")
}];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	document.title = `提现系统`;
	const role = localStorage.getItem('ms_username');
	if (!role && to.path !== '/login') {
		next('/login');
	} else if (to.meta.permission) {
		// 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
		role === 'admin' ?
			next() :
			next('/403');
	} else {
		next();
	}
});

export default router;
